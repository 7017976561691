/* eslint-disable prettier/prettier */
import React, { FC, useState, useContext } from 'react';
import {
  DataLayerContext,
  DataLayerContextType,
} from '../../providers/data-layer/data-layer.provider';
import GtmEventNameEnum from '../../../models/gtm-event-name-enum';
import ProductTypeEnum from '../../../models/product-type-enum';
import { QuickAddToolType } from '../../../models/quick-add-tool';
import QuickAddToolForm from '../../molecules/quick-add-tool-form/quick-add-tool-form';
import useAddMultipleToBasket from '../../providers/basket/hooks/use-add-multiple-to-basket';
import useUser from '../../providers/user/hooks/use-user.hook';
import styles from './quick-add-tool.module.scss';
import PlacementEnum from '../../../models/placement-enum';

interface Props extends QuickAddToolType {
  readonly multiple?: boolean;
  readonly small?: boolean;
}

const QuickAddTool: FC<Props> = ({ multiple, labels, small }: Props) => {
  const addMultipleToBasket = useAddMultipleToBasket(
    {
      addProductErrorHeading: labels.addProductErrorHeading,
      addProductErrorDescription: labels.addProductErrorDescription,
      addProductErrorBody: labels.addProductErrorBody,
      insufficientClearanceStockError: labels.insufficientClearanceStockError,
    },
    {
      clearQuoteAccept: labels.clearQuoteAccept,
      clearQuoteCancel: labels.clearQuoteCancel,
      clearQuoteDescription: labels.clearQuoteDescription,
      clearQuoteHeading: labels.clearQuoteHeading,
      clearQuoteHelp: labels.clearQuoteHelp,
    }
  );

  const { pushToDataLayer } =
    useContext<DataLayerContextType>(DataLayerContext);
  const [formSuccessfullySubmitted, setFormSuccessfullySubmitted] =
    useState<boolean>(false);
  const [formLoading, setFormLoading] = useState<boolean>(false);
  const customerCodeLabel = labels.quickAddToolCustomerCodeFieldLabel;
  const partNumberLabel = labels.quickAddToolPartNumberFieldLabel;
  const [selectedTab, setSelectedTab] = useState<string>(partNumberLabel);
  const user = useUser();

  const onFormSubmit = async (
    lineItems: { sku: string; quantity: number }[],
    onComplete: () => void
  ) => {
    setFormLoading(true);

    const updatedBasket = await addMultipleToBasket(
      lineItems.map((lineItem) => ({
        variantSku: lineItem.sku,
        quantity: lineItem.quantity,
      }))
    );

    if (updatedBasket.lineItemIssues?.length) {
      pushToDataLayer(GtmEventNameEnum.QuickError, {
        productType: ProductTypeEnum.Website,
        errorMessage: labels.addProductErrorHeading,
        productCode: updatedBasket.lineItemIssues
          .map((issue) => issue.variantSku)
          .join(','),
      });
    }

    if (updatedBasket.lineItemIssues?.length !== lineItems.length) {
      onComplete();
    }

    if (!updatedBasket.lineItemIssues?.length) {
      setFormSuccessfullySubmitted(true);
    }

    setFormLoading(false);

    setTimeout(() => {
      setFormSuccessfullySubmitted(false);
    }, 2000);

    pushToDataLayer(GtmEventNameEnum.AddToCart, {
      ecommerce: {
        add: {
          products: updatedBasket.lineItems.map((lineItem) => {
            return {
              price: lineItem.buyPriceFormatted,
              productImage: lineItem.product.properties.webImageLarge1425,
              productName: lineItem.product.name,
              productCode: lineItem.product.variantSku,
              masterCode: lineItem.product.sku,
              listPrice: lineItem.listPriceFormatted,
              quantity: lineItem.quantitySatisfied,
            };
          }),
        },
      },
      basketValue: updatedBasket.formattedTotal,
      placement: PlacementEnum.Dashboard,
      productType: ProductTypeEnum.Website,
    });
  };

  return (
    <div
      className={`${
        small
          ? `lg:py-component-spacing ${multiple ? 'xl:py-2' : 'xl:pb-2'}`
          : 'lg:py-component-spacing'
      } py-component-spacing-mobile`}
    >
      <div
        className={
          `${small ? '' : 'lg:flex-row lg:pt-0 lg:px-6 xl:px-10 '}` +
          'bg-french-blue flex flex-col items-center justify-between overflow-hidden pt-6 px-4 rounded-lg w-full'
        }
      >
        <h5
          className={`${
            small ? '' : 'lg:mb-0 '
          } page-heading-five mb-8 text-white`}
        >
          {labels.quickAddToolHeadingLabel}
        </h5>

        <div className={`${styles.container} ${small ? styles.small : ''}`}>
          <button
            className={
              `${selectedTab === partNumberLabel ? styles.selected : ''} ` +
              `${styles.button} ` +
              `${small ? styles.small : ''}`
            }
            onClick={() => setSelectedTab(partNumberLabel)}
            type="button"
          >
            {partNumberLabel}
          </button>

          {user && (
            <button
              className={
                `${selectedTab === customerCodeLabel ? styles.selected : ''} ` +
                `${styles.button} ` +
                `${small ? styles.small : ''}`
              }
              onClick={() => setSelectedTab(customerCodeLabel)}
              type="button"
            >
              {customerCodeLabel}
            </button>
          )}
        </div>

        <QuickAddToolForm
          customerCode={selectedTab === customerCodeLabel}
          formLoading={formLoading}
          formSuccessfullySubmitted={formSuccessfullySubmitted}
          labels={labels}
          onSubmit={(lineItems, onComplete) => {
            onFormSubmit(lineItems, onComplete);

            if (selectedTab === customerCodeLabel) {
              pushToDataLayer(GtmEventNameEnum.QuickAdd, {
                productType: ProductTypeEnum.Website,
              });
            } else {
              pushToDataLayer(GtmEventNameEnum.QuickCustomer, {
                productType: ProductTypeEnum.Website,
              });
            }
          }}
          selectedTab={selectedTab}
          small={small}
          multipleProducts={multiple}
        />
      </div>
    </div>
  );
};

QuickAddTool.defaultProps = {
  multiple: false,
  small: false,
};

export default QuickAddTool;
