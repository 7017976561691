import { Form, Formik, FormikProps } from 'formik';
import { AnimatePresence, motion } from 'framer-motion';
import React, { FC, useContext, useState } from 'react';
import useIsBreakpoint from '../../../hooks/use-is-breakpoint/use-is-breakpoint';
import {
  DataLayerContext,
  DataLayerContextType,
} from '../../providers/data-layer/data-layer.provider';
import BreakpointEnum from '../../../models/breakpoint-enum';
import GtmEventNameEnum from '../../../models/gtm-event-name-enum';
import { ProductType } from '../../../models/product-type';
import ProductTypeEnum from '../../../models/product-type-enum';
import { QuickAddToolType } from '../../../models/quick-add-tool';
import categoryStructure from '../../../services/content-api/endpoints/category/structure';
import searchSku from '../../../services/search-api/endpoints/search/sku';
import ButtonSecondary from '../../atoms/button-secondary/button-secondary';
import FormText from '../../atoms/form-text/form-text';
import IcomoonIcon from '../../atoms/icomoon-icon/icomoon-icon';
import LoadingSpinner from '../../atoms/loading-spinner/loading-spinner';
import close from '../../providers/overlay/close.overlay.actions';
import open from '../../providers/overlay/open.overlay.actions';
import { OverlayDispatchContext } from '../../providers/overlay/overlay.provider';
import useUser from '../../providers/user/hooks/use-user.hook';
import QuickAddSearchModal from '../quick-add-search-modal/quick-add-search-modal';

interface QuickAddToolFormValues {
  readonly lineItems: {
    readonly sku: string;
    readonly quantity: number;
  }[];
}
interface Props extends QuickAddToolType {
  readonly customerCode: boolean;
  readonly formLoading: boolean;
  readonly formSuccessfullySubmitted: boolean;
  readonly multipleProducts: boolean;
  readonly onSubmit: (
    lineItems: QuickAddToolFormValues['lineItems'],
    onComplete: () => void
  ) => void;
  readonly selectedTab: string;
  readonly small?: boolean;
}

const QuickAddToolForm: FC<Props> = ({
  customerCode,
  formLoading,
  formSuccessfullySubmitted,
  multipleProducts,
  labels,
  onSubmit,
  selectedTab,
  small,
}: Props) => {
  const { quickAddToolAddButtonText, quickAddToolQuantityFieldLabel } = labels;
  const { pushToDataLayer } =
    useContext<DataLayerContextType>(DataLayerContext);
  const overlayDispatch = useContext(OverlayDispatchContext);
  const breakpoint = useIsBreakpoint();
  const [skuSearchLoading, setSkuSearchLoading] = useState<boolean>(false);
  const user = useUser();

  const selectProduct = (
    products: ProductType[],
    onChooseProduct: (product: ProductType) => void
  ) => {
    overlayDispatch(
      open(
        <QuickAddSearchModal
          labels={labels}
          products={products}
          onSubmit={(product) => {
            onChooseProduct(product);
            overlayDispatch(close());
          }}
        />
      )
    );

    pushToDataLayer(GtmEventNameEnum.QuickSearch);
  };

  const suggestProducts = async (
    sku: string,
    onChooseSku: (sku: string) => void
  ) => {
    if (!sku.length) {
      return;
    }

    const { catalogId } = await categoryStructure();
    const lineItems = await searchSku(
      catalogId,
      sku,
      user && customerCode ? user.accountNumber : null
    );

    selectProduct(
      lineItems.map((lineItem) => lineItem.product),
      (product) => onChooseSku(product.variantSku)
    );
  };

  const selectOrSuggestProduct = async (
    sku: string,
    onChooseSku: (sku: string) => void
  ) => {
    setSkuSearchLoading(true);

    const { catalogId } = await categoryStructure();
    const lineItems = await searchSku(
      catalogId,
      sku,
      user && customerCode ? user.accountNumber : null
    );

    setSkuSearchLoading(false);

    const hasNoMatches = lineItems.length === 0;
    const exactMatch = lineItems.find(
      (line) => line.product.variantSku.toLowerCase() === sku.toLowerCase()
    );

    if (exactMatch) {
      onChooseSku(exactMatch?.product?.variantSku);

      return;
    }

    // If there are no matches we submit the product code anyway
    // to trigger the invalid code modal.
    if (hasNoMatches) {
      onChooseSku(lineItems[0]?.product?.variantSku || sku);

      return;
    }

    selectProduct(
      lineItems.map((lineItem) => lineItem.product),
      (product) => onChooseSku(product.variantSku)
    );
  };

  return (
    <Formik
      initialValues={{
        lineItems: new Array(multipleProducts ? 3 : 1).fill({
          sku: '',
          quantity: 1,
        }),
      }}
      onSubmit={({ lineItems }, { resetForm }) => {
        if (multipleProducts) {
          onSubmit(
            lineItems.filter((item) => item.sku?.length > 0),
            resetForm
          );
        } else {
          selectOrSuggestProduct(lineItems[0].sku, (sku) => {
            onSubmit([{ sku, quantity: lineItems[0].quantity }], resetForm);
          });
        }
      }}
    >
      {(props: FormikProps<QuickAddToolFormValues>) => (
        <Form
          className={`${
            small ? '' : 'lg:flex-row lg:pt-6 md:w-7/12 lg:w-8/12 xl:w-7/12 '
          } flex flex-col justify-between w-full`}
        >
          {multipleProducts && (
            <div className="flex flex-col space-y-6">
              <div className="flex flex-col space-y-2">
                {props.values.lineItems.map((item, index) => (
                  // eslint-disable-next-line react/no-array-index-key
                  <div key={index} className="flex space-x-2">
                    <div className="w-3/4">
                      <FormText
                        name={`lineItems.${index}.sku`}
                        iconName="search"
                        iconPosition="right"
                        onIconClick={() =>
                          suggestProducts(
                            props.values.lineItems[index].sku,
                            (sku) =>
                              props.setFieldValue(
                                'lineItems',
                                props.values.lineItems.map(
                                  (lineItem, lineItemIndex) =>
                                    index === lineItemIndex
                                      ? { sku, quantity: lineItem.quantity }
                                      : lineItem
                                )
                              )
                          )
                        }
                        label={index === 0 ? selectedTab : null}
                        labelColor="white"
                      />
                    </div>
                    <div className="w-1/4">
                      <FormText
                        type="number"
                        name={`lineItems.${index}.quantity`}
                        label={index === 0 ? 'Qty' : null}
                        labelColor="white"
                        paddingX="half"
                        textAlign="center"
                      />
                    </div>
                  </div>
                ))}
              </div>
              <div className="relative">
                <motion.div
                  animate={
                    formSuccessfullySubmitted
                      ? 'formSubmitted'
                      : 'formNotSubmitted'
                  }
                  variants={{
                    formSubmitted: { opacity: 0, scale: 0 },
                    formNotSubmitted: { opacity: 1, scale: 1 },
                  }}
                  className="flex"
                >
                  <ButtonSecondary
                    buttonType="submit"
                    name={quickAddToolAddButtonText}
                    className="flex-grow"
                    color="white"
                    disabled={formLoading || skuSearchLoading}
                  />
                </motion.div>

                <AnimatePresence>
                  {formSuccessfullySubmitted && (
                    <motion.div
                      className="absolute flex inset-0 items-center justify-center w-full"
                      initial={{ opacity: 0, scale: 0.75 }}
                      animate={{ opacity: 1, scale: 1 }}
                      exit={{ opacity: 0, scale: 0 }}
                    >
                      <LoadingSpinner className="h-8" color="white" />
                    </motion.div>
                  )}
                </AnimatePresence>
              </div>
            </div>
          )}
          {!multipleProducts && (
            <>
              <div className="mb-5 lg:w-60">
                <FormText
                  name="lineItems.0.sku"
                  label={selectedTab}
                  labelColor="white"
                  required
                  iconName="search"
                  iconPosition="right"
                  onIconClick={() =>
                    suggestProducts(props.values.lineItems[0].sku, (sku) =>
                      props.setFieldValue('lineItems', [
                        { sku, quantity: props.values.lineItems[0].quantity },
                      ])
                    )
                  }
                />
              </div>

              <div className="flex items-center justify-between">
                <div
                  className={`${
                    small ? '' : 'md:mr-5 xl:mr-15 '
                  } w-min mr-4 mb-6`}
                >
                  <FormText
                    label={quickAddToolQuantityFieldLabel}
                    labelColor="white"
                    min={1}
                    name="lineItems.0.quantity"
                    required
                    step={1}
                    type="number"
                  />
                </div>

                <div className="relative">
                  <motion.div
                    animate={
                      formSuccessfullySubmitted
                        ? 'formSubmitted'
                        : 'formNotSubmitted'
                    }
                    variants={{
                      formSubmitted: { opacity: 0, scale: 0 },
                      formNotSubmitted: { opacity: 1, scale: 1 },
                    }}
                  >
                    <ButtonSecondary
                      buttonType="submit"
                      color="white"
                      disabled={
                        props.values.lineItems[0].quantity <= 0 ||
                        !props.values.lineItems[0].sku ||
                        formSuccessfullySubmitted ||
                        formLoading ||
                        skuSearchLoading
                      }
                      name={
                        small && breakpoint !== BreakpointEnum.lg
                          ? quickAddToolAddButtonText.substring(
                              0,
                              quickAddToolAddButtonText.indexOf(' ')
                            )
                          : quickAddToolAddButtonText
                      }
                    />
                  </motion.div>

                  <AnimatePresence>
                    {formSuccessfullySubmitted && (
                      <motion.div
                        className="absolute flex inset-0 items-center justify-center w-full"
                        initial={{ opacity: 0, scale: 0.75 }}
                        animate={{ opacity: 1, scale: 1 }}
                        exit={{ opacity: 0, scale: 0 }}
                      >
                        <IcomoonIcon
                          className="mr-4"
                          color="lemon-meringue"
                          name="check-circle"
                        />
                        <span className="page-heading-six text-white">
                          Added to basket!
                        </span>
                      </motion.div>
                    )}
                  </AnimatePresence>
                </div>
              </div>
            </>
          )}
          {multipleProducts && (
            <div className="pt-4">
              {props.values.lineItems.length < 9 && (
                <button
                  type="button"
                  className="w-full py-4 flex items-center justify-center space-x-4"
                  onClick={() => {
                    props.setFieldValue('lineItems', [
                      ...props.values.lineItems,
                      ...new Array(3).fill({
                        sku: '',
                        quantity: 1,
                      }),
                    ]);

                    pushToDataLayer(GtmEventNameEnum.QuickExtra, {
                      productType: ProductTypeEnum.Website,
                    });
                  }}
                >
                  <IcomoonIcon name="add" color="white" size="sm" />
                  <span className="text-white text-sm">
                    {labels.quickAddAnotherThreeProducts}
                  </span>
                </button>
              )}
            </div>
          )}
        </Form>
      )}
    </Formik>
  );
};

QuickAddToolForm.defaultProps = {
  small: false,
};

export default QuickAddToolForm;
